import React from "react"

import Week from '../components/week';

export default ({ days }) => (
  <section className="section calendar">
    <div className="section-wrapper">
      {[0, 1, 2, 3].map((i) => <Week key={i} index={i} days={days} />)}
    </div>
  </section>
);
