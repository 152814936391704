import React from "react"

import Calendar from '../components/calendar';
import Layout from '../components/layout';

export default ({ pageContext }) => {
  const { days } = pageContext;

  return (
    <Layout>
      <section className="section intro">
        <div className="section-wrapper">
          <img className="divider" src="/divider.png" />

          <p>Each year, Christians across the world celebrate Advent as a season of anticipation— of waiting in the darkness for the Light of the world to come.</p>

          <p>At the end of a year like this one, the idea of waiting in darkness might seem less appealing than ever. Still, Advent invites us to keep leaning in. This season, as we wonder at the Incarnation— the ultimate leaning-in of God towards us— we ask how the humanity of Christ enables us to pray, presently and with hope, in the limitation and the glory of our own incarnations.</p>

          <p>Click on the guides below for daily prayers and scripture passages to read with your family or enjoy on your own. Or, check out the All Souls Daily Worship podcast to hear these read by members of our community.</p>
        </div>
      </section>

      <Calendar days={days} />
    </Layout>
  );
};
