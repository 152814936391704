import dayjs from 'dayjs';
import React from 'react';

const truncate = (scripture) => {
  return scripture
    .replace('Thessalonians', 'Thess.')
    .replace('Revelation', 'Rev.')
    .replace('Matthew', 'Matt.')
    .replace('Ephesians', 'Eph.')
    .replace('Zechariah', 'Zech.')
    .replace('Philippians', 'Phili.')
    .replace('Hebrews', 'Heb.');
}

const getReferences = (day) => (
  day.scripture.map(truncate).join('<br />')
);

export default ({ day }) => {
  const date = dayjs(day.date);
  const disabled = date.isAfter(dayjs());
  const dayUrl = `/${dayjs(day.date).format('MM-DD-YY')}`;

  let className = 'day';
  if (disabled) {
    className += ' disabled';
  }

  if (day.description) {
    className += ' day--event';
  }

  // Allow alt/opt clicking for testing
  const onClick = (e) => {
    if (e.altKey) {
      window.location = dayUrl;
    }
  };

  return (
    <a
      className={className}
      href={disabled ? undefined : dayUrl}
      onClick={onClick}
    >
      <div className="day-contents">
        <h3>{date.format('ddd')}</h3>
        {day.description ? (
          <p>{day.description} + daily readings</p>
        ) : (
          <p className="reference" dangerouslySetInnerHTML={{ __html: getReferences(day) }} />
        )}
      </div>
    </a>
  );
};
