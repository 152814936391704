import dayjs from 'dayjs';
import React from 'react';

import Day from './day';

export default ({ days, index }) => {
  const start = index * 7;
  const weekDays = days.slice(start, start + 7);
  const firstDate = dayjs(weekDays[0].date).format('MM.DD');
  const lastDate = dayjs(weekDays[weekDays.length - 1].date).format('MM.DD');

  return (
    <div>
      <div className="title-wrapper">
        <h2 className="week-title">{`Week ${index + 1}: ${firstDate}-${lastDate}`}</h2>
      </div>
      <div className="week">
        {weekDays.map((day, index) => <Day day={day} key={day.date} />)}
      </div>
    </div>
  );
};
